/*jshint esversion: 6 */
"use strict";

export var epSupportMixin = {
	data() {
		return {
			supportMessage: "",
			supportEmail: "",
			supportCell: "",
			supportMessageSent: "",
		}
	},

	methods: {

		submitSupport: function () {
			var that = this;
			var spinId = that.spinStart();
			that.$http.post('/api/glimpse/support/', {cell: that.supportCell, email: that.supportEmail, message: that.supportMessage}).then(function (response) {
				that.spinStop(spinId);
				that.supportMessageSent = that.supportMessage;
				that.supportMessage = "";
			});
		},

	}

}