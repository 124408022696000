/*jshint esversion: 6 */
"use strict";

// import Vue from "vue/dist/vue";
import Vue from "vue";
import {
  ivbus
} from "iv-base-components";

var TwilioVideo = require("twilio-video");

export var ivTwilioWebrtcMixin = {
  mounted: function () {
    this.iv_room = {};
  },

  data() {
    return {
      roomName: "",
      remoteTrackAttached: false,
      logLevel: "warn",
      shouldReconnect: true,
      screenTrackList: [],
    };
  },

  methods: {
    twilioJoin: function (roomInfo, camera) {
      console.log("twilioJoin called");
      var that = this;
      // request the token from the server, then connect to Video!
      that.roomName = roomInfo.video_room;
      that.iv_room.dataTrack = new TwilioVideo.LocalDataTrack();

      TwilioVideo.createLocalTracks({
          audio: true,
          video: {
            deviceId: camera.deviceId
          }
        })
        .then(function (track) {
          that.iv_room.cameraTrack = track;

          return TwilioVideo.connect(roomInfo.video_token, {
            logLevel: that.logLevel,
            name: that.roomName,
            tracks: [
              that.iv_room.dataTrack,
              that.iv_room.cameraTrack[0],
              that.iv_room.cameraTrack[1]
            ],
            networkQuality: {
              local: 1,
              remote: 1
            }
          });
        })
        .then(function (room) {
          console.log("[TWILIO] connected to room");
          that.iv_room.activeRoom = room;
          that.iv_room.networkQuality = 0;

          ivbus.setFlag("twilioRoomActive", true);

          that.iv_room.activeRoom.on("disconnected", function () {
            if (that.shouldReconnect) {
              that.startCamera();
            }
          });

          var remoteContainer = document.getElementById("remote-media");

          room.participants.forEach(participantConnected);
          room.on("participantConnected", participantConnected);

          room.on("participantDisconnected", participantDisconnected);
          room.once("disconnected", error =>
            room.participants.forEach(participantDisconnected)
          );

          that.sessionAppend({
            action: "EVAL_JOIN_ROOM_TWILIO_SUCCESS_" + that.$route.params.token
          });

          function participantConnected(participant) {
            console.debug('Participant "%s" connected', participant.identity);

            participant.on("trackSubscribed", track =>
              trackSubscribed(remoteContainer, track)
            );
            participant.on("trackUnsubscribed", trackUnsubscribed);

            participant.tracks.forEach(publication => {
              if (publication.isSubscribed) {
                trackSubscribed(remoteContainer, publication.track);
              }
            });

            participant.on(
              "networkQualityLevelChanged",
              printNetworkQualityStats
            );
            // document.body.appendChild(remoteContainer);
          }

          function participantDisconnected(participant) {
            console.debug('Participant "%s" disconnected', participant.identity);
            // Remove all video and audio children
            if (remoteContainer.hasChildNodes()) {
              var children = remoteContainer.childNodes;
              var toRemove = [];
              for (const child of remoteContainer.childNodes) {
                // Requires ECMAscript6
                if (["VIDEO", "AUDIO"].includes(child.nodeName)) {
                  toRemove.push(child)
                }
                // NOTE: List is live, adding or removing children will change the list
                // Let's not change the list
              }
              for (const rem of toRemove) {
                remoteContainer.removeChild(rem)
              }
            }
          }

          function trackSubscribed(remoteContainer, track) {
            if (track.kind === "data") {
              track.on("message", function (message) {
                that.receiveMessage(message);
              });
            } else {
              remoteContainer.appendChild(track.attach());
            }
          }

          function trackUnsubscribed(track) {
            // TODO: for now track.detach() doens't seem to be working.
            // track.detach().forEach(element => element.remove());
          }

          function printNetworkQualityStats(networkQualityLevel) {
            if (
              (that.iv_room.networkQuality < 2 && networkQualityLevel > 2) ||
              (that.iv_room.networkQuality > 2 && networkQualityLevel < 2)
            ) {
              // if the networkQuality used to be high or low and has now swapped, let us know
              that.iv_room.networkQuality = networkQualityLevel;
              that.sessionAppend({
                action: "EVAL_NETWORK_QUALITY_" + that.$route.params.token,
                quality: networkQualityLevel
              });
            }
          }
        })
        .catch(function (error) {
          console.error("Unable to connect to Room: " + error.message);
          that.showMessage(
            "Unable to join interview room. Please refresh the page or restart your computer to try again.",
            "alert-danger"
          );
          console.error(error);
          that.sessionAppend({
            action: "EVAL_JOIN_ROOM_TWILIO_ERROR_" + that.$route.params.token,
            error: error.stack
          });
          that.sessionSend();
        });



      // When we are about to transition away from this page, disconnect
      // from the room, if joined.
      window.addEventListener("beforeunload", function () {
        if (that.iv_room.activeRoom) {
          that.iv_room.activeRoom.disconnect();
        }
      });
    },

    twilioLeave: function () {
      this.shouldReconnect = false;
      this.iv_room.activeRoom.disconnect();
    },

    twilioJoinShareScreen(stream) {
      var that = this;
      var screenTrack = stream.getVideoTracks()[0];
      var deviceId = stream.getVideoTracks()[0].getSettings().deviceId;
      that.screenTrackList.push({
        "id": deviceId,
        "screenTrack": screenTrack
      });
      var spinId = that.spinStart();
      ivbus.getFlag("twilioRoomActive").then(() => {
        that.spinStop(spinId);
        that.iv_room.activeRoom.localParticipant.publishTrack(screenTrack);
        that.sessionAppend({
          action: "EVAL_START_SCREENSHARE_SUCCESS_" + that.$route.params.token,
        });
      })
    },

    twilioLeaveShareScreen(stream) {
      var deviceId = stream.getVideoTracks()[0].getSettings().deviceId;
      for (var i = this.screenTrackList.length - 1; i >= 0; i--) {
        if (this.screenTrackList[i].id == deviceId) {
          var screenTrack = this.screenTrackList[i].screenTrack;
          this.iv_room.activeRoom.localParticipant.unpublishTrack(screenTrack);
        }
      }
    }
  }
};