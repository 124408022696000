<template>
<div id="interview" v-cloak>
  <div class="container main-container-grey" style="max-width: 500px;" v-if="env != undefined">
    <div class="ep-recording-body">
      <div v-if="current_view == 'browser_support'">
        <div class="row">
          <div class="col-md-12 text-center">
            Please ensure you are using the latest version of the Chrome browser to access this feature.
          </div>
        </div>
      </div>

      <!-- LANDING PAGE -->
      <div v-if="current_view == 'landing'">
        <div class="row">
          <div class="col-md-12 text-center">
            <button class="btn btn-massive-grey" v-on:click="goNext('check')">
              <img :src="env.staticAddr + '/ivgcs/img/epic-play-icon.png'" />
              <div>
                <b>Click here to Start Recording</b>
              </div>
            </button>
          </div>
          <div class="col-md-12" style="margin-top:15px;" v-show="selectedInterview.ivType == 'junior' && selectedInterview.iver_feedback">
            <div>Our interviewer wrote the following notes that may be helpful in providing this student with feedback. Click <a href="/junior-feedback" target="_blank" rel="opener">here</a> to view general notes on providing feedback.</div>
            <div class="well">{{selectedInterview.iver_feedback}}</div>
          </div>
        </div>
      </div>

      <div class="row" v-show="current_view == 'recording'">
        <div class="col-md-12 text-center">
          <div class="ep-sub-header">
            Recording...
          </div>
        </div>
      </div>
      <div class="row" v-show="current_view == 'ready'">
        <div class="col-md-12">
          <transition name="update" mode="out-in">
            <div class="ep-sub-header text-center">
              Looking good! Ready to begin?
            </div>
          </transition>
        </div>
      </div>

      <!-- Audio Alert -->
      <div class="row" v-show="(current_view == 'ready' || current_view == 'recording') && audioTracker.working == false">
        <div class="col-md-12 text-center">
            <div class="panel">
              <div class="panel-body bg-danger text-danger">
                Oops, we're not getting sound from your microphone! Make sure you are talking loudly enough. If this message doesn't go away, try selecting a different microphone below.
              </div>
            </div>
        </div>
        <div class="col-md-12 text-center">
          Select Microphone:
          <select class="form-control" v-model='microphoneSelection.deviceId' v-on:change="micSelection()">
              <option v-for="devices in inputDevices" :value="devices.deviceId">{{devices.label}}</option>
          </select>
        </div>
      </div>

      <!-- viewfinder -->
      <div class="row" style="margin-bottom: 0px;" v-show="current_view == 'check' || current_view == 'ready' || current_view == 'recording'">
        <div class="col-md-12">
          <div style="position: relative;" id="viewfinder-wrapper">
            <div style="position: absolute; right: 3px; top: 3px; padding: 15px; background-color: #fff; opacity: 0.85; z-index: 1; font-size: 16px;" v-bind:style="{color: time > 15 ? '#333' : '#f00'}">{{computedTime}}</div>
          </div>
          <video v-if="showViewfinder" autoplay="true" id="viewfinder" playsinline style="width: 100%;" muted></video>
        </div>
      </div>

      <!-- button -->
      <div style="position: relative; text-align: center;">
        <div style="position: absolute; bottom: 40px; width: 100%;">
          <div v-show="current_view == 'check'">
            <span class="btn btn-default btn-flashorange" v-on:click="startCamera()">
              Turn on Camera
            </span>
          </div>
          <div v-show="current_view == 'ready'">
            <span class="btn btn-default btn-transparent btn-flashorange" v-on:click="startRecording()">
              Start Recording
            </span>
          </div>
          <div v-show="current_view == 'recording'">
            <span class="btn btn-default btn-transparent" v-on:click="endRecording()">
              Click here when done
            </span>
          </div>
        </div>
      </div>

      <!-- Microphone Selection Tool -->
      <div class="row" v-show="(current_view == 'ready' || current_view == 'recording') && audioTracker.working == true && selectionToolLock == true">
            <div class="col-md-12 text-center">
                Microphone options:
                <select class="form-control" v-model='microphoneSelection.deviceId' v-on:change="micSelection()">
                    <option v-for="devices in inputDevices" :value="devices.deviceId">{{devices.label}}</option>
                </select>
            </div>
      </div>

      <!-- playback -->
      <div v-show="current_view == 'review' || current_view == 'school' || current_view == 'send'">
        <div class="row">
          <div class="col-xs-12">
            <div class="videoWrapper">
              <video
                v-if="showPlayback"
                class="video-js vjs-default-skin"
                id="playback"
                width="480px"
                height="268px"
                preload="none"
                src="https://initialview.com/ivgcs/video/blank.mp4"
                type="video/mp4"
                playsinline
                webkit-playsinline="webkit-playsinline"
              />
            </div>
          </div>
        </div>

        <div>
          <div class="row">
            <div class="col-xs-12 text-center">
              <button v-show="!recordAgainMessage" class="btn btn-default" v-on:click="recordAgainMessage = true">Delete and Record Again</button>

              <div v-show="recordAgainMessage">
                <div class="ep-header">Are you sure?</div>
                <div class="ep-sub-header">You're about to delete what you recorded and start over.</div>
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <button class="btn btn-default" v-on:click="confirmRecordAgain()">That's fine; I need a do-over</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <button class="btn btn-orange" v-on:click="recordAgainMessage = false">Wait, I want to keep what I've got</button>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 text-center">
              <textarea class="form-control" placeholder='Thank you for applying to our school! Please visit our school’s site here (www.infosession.com) to learn more about our upcoming virtual events!' style='width:100%; height:150px;resize:vertical;' v-model="textMessage"></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-5 text-right">
              <p style="color:grey;">Delete video in 30 days</p>
            </div>
            <div class="col-xs-2 text-center" style="padding: 0;">
              <!-- Rounded switch -->
              <label class="switch">
                <input type="checkbox" v-model="preserveVideo">
                <span class="switchSlider round"></span>
              </label>
            </div>
            <div class="col-xs-5 text-left">
              <p style="color:#e96724;">Preserve the video</p>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 text-center">
              * This response video will automatically be removed from the student’s account after 30 days. It will also be deleted on your side after 30 days. If you instead prefer to preserve access to the response video in your InitialView account, switch the toggle.
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 text-center">
              <div style="padding: 10px;">
                <b>Ready to send your video?</b>
              </div>
              <button v-show="videoFileUid" class="btn btn-orange" v-on:click="sendToStudent()">Send to {{studentName || " your High Fives"}}</button>
              <button v-show="!videoFileUid" v-bind:disabled="!videoFileUid" class="btn btn-orange">Uploading...</button>
            </div>
          </div>
        </div>
      </div>

      <!-- SEND -->
      <div v-show="current_view == 'sent'">
        <div class="row"></div>
        <div class="text-center" v-show="!(highFives && studentName)">
        </div>
        <div style="padding: 40px 50px;">
          <div class="text-center">
            <div style="padding-bottom: 20px;">
              <b> Your video message has been sent to {{studentName || " your High Fives"}}.</b>
            </div>
            <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">All done!</button>
          </div>
        </div>
        <div class="row" v-show="showUploadWarning">
          <div class="col-xs-12 text-center" v-show="originalVideoUploadProgress && originalVideoUploadProgress < 100">
            <div>
              Your video is still uploading, please do not close this tab until the upload is complete!
            </div>
            <div v-show="originalVideoUploadProgress">
              {{originalVideoUploadProgress}}%
            </div>
          </div>
          <div class="col-xs-12 text-center" v-show="originalVideoUploadProgress && originalVideoUploadProgress == 100">
            Your video is finished uploading, you may now close this tab!
          </div>
          <div class="text-center">
            <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">Unlock more love!</button>
          </div>
        </div>
      </div>


      <!-- response video list -->
      <div class="row text-center" v-if="response_list.length > 0 && current_view == 'landing'">
        <div style="padding-top: 30px;">
          <hr>
          <b>Previously Sent Video Messages to {{studentName}}</b>
        </div>
      </div>
      <div v-if="current_view == 'landing'" class="row" v-for="r in response_list" v-bind:key="r.created">
        <div class="col-xs-12">
          <p>Response sent on {{r.created.substr(0,10)}}</p>          
          <div class="videoWrapper" width="240px" v-if="r.videoURL != null">
            <video
              class="video-js vjs-default-skin"
              width="240px"
              height="134px"
              preload="auto"
              :src="r.videoURL"
              type="video/mp4"
              playsinline
              webkit-playsinline="webkit-playsinline"
              controls
            />
          </div>
          <div v-if="r.videoURL == null" class="removedVideoNotice">
            <p>Response sent on {{r.created.substr(0,10)}}. <br>  <br>  Removed after 30 days.</p>
          </div>
        </div>
      </div>


      <div class="modal fade" tabindex="-1" role="dialog" id="record-again-modal">
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body text-center">
              <div class="ep-header">Are you sure?</div>
              <div class="ep-sub-header">You're about to delete what you recorded and start over.</div>
              <div class="row">
                <div class="col-xs-12 text-center">
                  <button class="btn btn-default" v-on:click="confirmRecordAgain()">That's fine; I need a do-over</button>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 text-center">
                  <button class="btn btn-orange" v-on:click="closeModal('record-again-modal')">Wait, I want to keep what I've got</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" tabindex="-1" role="dialog" id="record-failed-modal">
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body text-center">
              <div>It looks like the internet connection is unstable - please try recording again using another connection!</div>
              <div class="row">
                <div class="col-xs-12">
                  <button class="btn btn-orange" v-on:click="resetFailedRecording()">OK</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="needHelp" tabindex="-1" role="dialog" aria-hidden="true" style="z-index: 1000001;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">We're here to help!</h4>
          </div>
          <div class="modal-body">
            <p>If you're having any trouble, write us a message below or email us at <a href="mailto:contact@initialview.com">contact@initialview.com</a>!</p>
            <p>Note that recording works best in the Chrome browser.</p>
            <div class="form-group">
              <input class="form-control" v-model="supportEmail" placeholder="your-email@example.com" />
            </div>
            <div class="form-group">
              <input class="form-control" v-model="supportCell" placeholder="cellphone" />
            </div>
            <div class="form-group">
              <textarea class="form-control" v-model="supportMessage" rows="3" placeholder="Write your message here"></textarea>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <button class="btn btn-default pull-right" v-bind:disabled="!supportMessage" v-on:click="submitSupport()">Submit</button>
              </div>
            </div>
            <div v-if="supportMessageSent" class="row">
              <div class="col-xs-12" style="color: #e96724;">We've received your message and will be in touch soon!</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>

  import { ivRecordClientMixin } from "../mixins/ep-record-mixin-pack.js";
  import { epSupportMixin } from "../mixins/ep-support-mixin-pack.js";
  import { ivTwilioWebrtcMixin } from "../mixins/twilio-webrtc-mixin.js";

  import { ivModalMixin, ivAlertMixin, ivSpinnerMixin, getParameterByName, ivDiagnosticsMixin } from "@/mixins/base-components-pack.js";

	import ivbus from "@/utils/ivbus.js";
  import env from "../utils/env.js";
  import apiClient from "../utils/apiClient.js";
  import moment from "moment-timezone";

  export default {
    mixins: [ivModalMixin, ivAlertMixin, ivSpinnerMixin, ivRecordClientMixin, epSupportMixin, ivTwilioWebrtcMixin, ivDiagnosticsMixin],

    props: [
      'highFives',
      'selectedInterview',
    ],

    data() {
      return {

        textMessage:"",
        videoFileUid: "",
        preserveVideo: true,
        studentName: "",
        audioTracker: {working: null, status: 0, level: 0},
        inputDevices: [],
        microphoneSelection: {deviceId: "default"},
        selectionToolLock: false,
        onlyOnce: true,

        env: env,
        current_view: "landing",
        pre_record: true,
        isSafari: false,
        isChrome: false,
        playbackLoading: false,
        showViewfinder: true,
        showPlayback: true,

        recordAgainMessage:false,

        time: 30,
        questionTimer: null,

        uiMessageWaitShort: 5000,
        uiMessageWaitLong: 10000,

        upload_token: "",
        browser_support_message: "",

        videoProvider: "twilio",
        twilio_room_identifier: "",
        twilio_room_sid: "",

        camera: null,
        vid_currently_playing: "",

        showUploadWarning: false,

        response_list: [],
      };
    },

    watch: {
      selectedInterview: {
        handler() {
          var that = this;
          if (that.selectedInterview.ivType == "junior") {
            that.time = 300;
          }
        },
        immediate: true
      }
    },

    mounted: async function () {
      var that = this;

      that.findDevices();
      ivbus.$on('setVideoReplyCurrentView', function(val){
        if (that.current_view == "browser_support") {
          return;
        }
        that.current_view = val
      })

      that.studentName = await ivbus.getFlag("student_name");

      apiClient
        .get("/api/org/response_list/?v=" + getParameterByName('v'))
        .then(function (response) {
          if (response.data.status == 200) {
            that.response_list = response.data.response_list;
            that.$emit("iverThanks", response.data.iver_thanks);
          } else {
            console.debug("Fetch response_list status != 200");
          }
        })
        .catch(function (error) {
          that.showMessage("Unable to retrieve previous recordings.", "alert-danger");
        });

      ivbus.$on("videoRecordingUploadComplete", function (video_file_uid) {
        that.videoFileUid = video_file_uid
      });

      var isIos = function () {
        return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      };

      if (isIos()) {
        that.browser_support_message = "ios";
        that.goNext("browser_support");
        return;
      }

      that.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (that.isSafari) {
        that.browser_support_message = "safari";
        that.goNext("browser_support");
        return;
      }

      that.isChrome = /^.+(AppleWebKit\/)+((?!\/).)+(chrome\/).+(safari\/)[^\s]+$/i.test(navigator.userAgent);

      if (!that.isChrome) {
        that.browser_support_message = "notchrome"; // but allow them to continue, I suppose
        that.goNext("browser_support");
        return;
      }

    },

    computed: {
      computedTime () {
        var that = this;
        return Math.max(that.time, 0);
      }
    },

    methods: {
      sendToStudent: async function () {
        var that = this;
        console.log("Sending to student")
        that.showPlayback = false;
        var spinId = that.spinStart();

        var retries = 0;
        var vparam = getParameterByName('v');  // set this to null if you are submitting a genaric response

        var submitResponse = async function () {
          retries += 1;
          try {
              var response = await apiClient.post("/api/org/respond/?v=" + getParameterByName('v'),
                { 
                  retry: retries, 
                  video: that.videoFileUid, 
                  message: that.textMessage, 
                  delete: !that.preserveVideo
                })
              if (response.data.status == 200) {
                that.textMessage = "";
              }
          } catch (err) {
              that.showMessage("Problem contacting server, please retry.", "alert-danger", that.uiMessageWaitShort);
              that.spinStop(spinId);
              return
          }

          if (response.data.status == 200) {
            that.spinStop(spinId);
            ivbus.$emit("refreshStudents")
            try {
              // if we don't try-catch this, people who refresh the page at this point get stuck when this errors out.
              that.camera.getTracks().forEach(function (track) {
                track.stop();
              });
              that.twilioLeave();
            } catch {
              console.debug("no camera to stop and/or no twilio");
            }
            that.goNext("sent");
          } else {
            if (retries > 5) {
              that.spinStop(spinId);
              that.showMessage("Server response error, please contact support!", "alert-danger", that.uiMessageWaitShort);
            } else {
              setTimeout(submitResponse, 1000 * (Math.pow(retries, 1.5)))
            }
          }
        }

        setTimeout(submitResponse, 2000);

      },

      startCamera: function () {
        var that = this;
        // so here we set up viewfinder
        that.pre_record = false;
        that.videoFileUid = "";
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: {
              facingMode: "user",
            },
          })
          .then(function (camera) {
            that.camera = camera;
            const audioContext = new AudioContext();
            const analyser = audioContext.createAnalyser();
            const microphone = audioContext.createMediaStreamSource(camera);
            const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 1024;

            microphone.connect(analyser);
            analyser.connect(scriptProcessor);
            scriptProcessor.connect(audioContext.destination);
            scriptProcessor.onaudioprocess = function() {
                const array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                const arraySum = array.reduce((a, value) => a + value, 0);
                const average = arraySum / array.length;
                that.audioMonitor(average);
            };
            console.log(camera);
            $("#viewfinder")[0].srcObject = that.camera;
            that.joinInterviewRoom();
            that.goNext("ready");
          })
          .catch(function (error) {
            alert("Unable to capture your camera. Please ensure you are using the Chrome browser on your device.");
            console.error(error);
          });
      },

      audioMonitor: function(vol) {
        var that = this;
            
        that.audioTracker.status++;
        that.audioTracker.level = Math.round(vol / 10);
        if (that.audioTracker.level > 0) {
          that.audioTracker.working = true;
          //console.log("audio working")
        }
        if (that.audioTracker.status == 300) {
          if (that.audioTracker.working == null) {
            that.selectionToolLock = true;
            that.audioTracker.working = false;
          }
          if (that.audioTracker.working == false) {
            that.audioTracker.status = 0;
            that.selectionToolLock = true;
          }
        }
        if (that.audioTracker.status == 50) {
          if (that.audioTracker.working == false) {
            that.audioTracker.status = 0;
            that.selectionToolLock = true;
          }
          if (that.audioTracker.working == true) {
            //console.log('Audio is working')
            if (that.selectionToolLock == true && that.onlyOnce == true) {
              that.showMessage(
                "Microphone is working now!",
                "alert-success",
                5000
              );
              that.onlyOnce = false;
            }
            that.audioTracker.status = 0;
          }
        }
      },

      findDevices: function() {
            var that = this;
            if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                console.log("enumerateDevices() not supported.");
                return;
            }

            navigator.mediaDevices.enumerateDevices()
            .then(function(devices) {
                devices.forEach(function(device) {
                // console.log(device.kind + ": " + device.label +
                // " id = " + device.deviceId);
                if (device.kind == 'audioinput'){
                    that.inputDevices.push(device)
                }
                });
            })
            .catch(function(err) {
                console.log(err.name + ": " + err.message);
            });
            console.log(that.inputDevices)
      },

      micSelection: function() {
            var that = this;
            console.log(that.microphoneSelection.deviceId);
            console.log(that.camera);
            navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: that.microphoneSelection.deviceId
                },
                video: {
                    facingMode: "user"
                }
            }).then(function (camera) {
                that.camera = camera;
                const audioContext = new AudioContext();
                const analyser = audioContext.createAnalyser();
                const microphone = audioContext.createMediaStreamSource(camera);
                const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

                analyser.smoothingTimeConstant = 0.8;
                analyser.fftSize = 1024;

                microphone.connect(analyser);
                analyser.connect(scriptProcessor);
                scriptProcessor.connect(audioContext.destination);
                scriptProcessor.onaudioprocess = function() {
                    const array = new Uint8Array(analyser.frequencyBinCount);
                    analyser.getByteFrequencyData(array);
                    const arraySum = array.reduce((a, value) => a + value, 0);
                    const average = arraySum / array.length;
                    that.audioMonitor(average);
                };
                $("#viewfinder")[0].srcObject = camera;
            }).catch(function (error) {
                alert('Unable to capture your camera. Please ensure you are using the Chrome browser on your device.');
                console.error(error);
            });
      },

      joinInterviewRoom: function () {
        // this function is simply for creating a backup twilio room recording for this EP
        var that = this;

        if (this.videoProvider == "twilio") {
          // this will pass apitoken (browser_token or other) to get_ep_amelia_room_token
          apiClient
            .get("/api/twiliovideo/get_amelia_room_token/?v=" + getParameterByName('v'))
            .then(function (response) {
              if (response.data.video_room) {
                that.twilio_room_identifier = response.data.video_room;
                that.twilio_room_sid = response.data.room_sid;
                that.upload_token = response.data.upload_token;
              }
              if (response.data.status == 200) {
                that.twilioJoin(response.data, that.camera);
              } else {
                console.debug("IVER fetch token status != 200");
              }
            })
            .catch(function (error) {
              console.error(error.stack);
              that.showMessage("Unable to join recording server.", "alert-danger");
              console.error(error);
              // that.sessionAppend({
              //   action: "IIAB2_JOIN_ROOM_ERROR_" + that.$route.params.bookingid,
              //   error: error.stack
              // });
              // that.sessionSend();
            });
        } // end twilio function
      },
      startRecording: async function () {
        var that = this;
        var decrementTime = function () {
          that.time -= 1;
          if (that.time == -2) {
            that.endRecording();
          } else {
            that.questionTimer = setTimeout(decrementTime, 1000);
          }
        };
        that.recordingVideoStart(that.camera);
        that.goNext("recording", true);
        that.questionTimer = setTimeout(decrementTime, 1000);
      },
      endRecording: function () {
        var that = this;
        var clickThreshold = that.selectedInterview.ivType == 'junior' ? 297 : 27;
        if (that.time > clickThreshold) {
            return; // make Done unclickable for 3 seconds to prevent double clicking
        }
        that.playbackLoading = true;
        clearTimeout(that.questionTimer);
        that.time = that.selectedInterview.ivType == 'junior' ? 300 : 30;
        that.recordingVideoStop(function () {
          that.previewRecording();
          that.twilioLeave();
          that.camera.getTracks().forEach(function (track) {
            track.stop();
          });
          that.videoFileUid = ""; // reset videoFileUid when recording ends, in case a previous recording finished uploading while the new recording was in progress. 
          that.uploadVideoRecording(); // eager upload
          that.goNext("review");
          //STP: For testing the svg fade in. I couldn't reach the 'sent' view...
          //~ document.querySelector('#unlocked-svg2').classList.remove('revealNow')
          //~ document.querySelector('#unlocked-svg2').classList.add('revealNow')
        });
      },
      resetFailedRecording: function () {
        var that = this;
        this.closeModal("record-failed-modal");
        this.showViewfinder = true;
        setTimeout(function () {
          that.goNext("check");
          that.startCamera();
        });
      },
      recordAgain: function () {
        that.recordAgainMessage = true;
      },
      confirmRecordAgain: function () {
        var that = this;
        that.recordAgainMessage = false;
        that.myPlayer.reset();
        $("#my-player").remove();
        setTimeout(function () {
          that.goNext("check");
          that.startCamera();
        });
      },
      goNext: function (next) {
        var that = this;
        var goHere = next;
        setTimeout(function () {
          $("html, body").animate({
            scrollTop: $("body").offset().top,
          });
          that.current_view = goHere;
        }, 100);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .main-container-grey {
    background-color: #f3f0f1;
  }

  .btn-massive-grey {
    width: 300px;
    height: 200px;
    background-color: #e6e6e6;
    color: $orange;
    border-radius: 7px;
  }
  .orange-bar {
    background: $orange;
    color: #fff;
    padding: 7px;
  }
  .epic-school-logo {
    max-width: 100%;
    padding: 5px;
    max-height: 50px;
    text-align: center;
  }
  .epic-school-logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 10px;
  }
  .epic-school-video > video {
    width: 80%;
    padding: 5px;
    max-height: 120px;
  }
  @keyframes flashorange {
    from {
      border-color: #ccc;
    }
    to {
      border-color: #e96724;
    }
  }
  .ep-recording-header {
    display: flex;
    justify-content: space-between;
    text-align: right;
    padding: 15px;
    margin-top: 15px;
    width: 100%;
    color: #000;
    background-color: #f2f0f1;
    height: 80px;
  }
  .epic-header-email {
    font-size: 1em;
    background: $orange;
    padding: 3px 12px;
    border-radius: 8px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 270px;
  }
  .ep-recording-body {
    width: 100%;
    margin-bottom: 15px;
    background-color: #f3f0f1;
    padding: 10px;
    margin: 0;
    border: none;
  }
  .ep-header {
    font-size: 24px;
    color: #e96724;
    margin-top: -5px;
  }
  .ep-sub-header {
    font-size: 16px;
    margin: 10px 0 10px 0;
  }
  .ep-orange-bold-font {
    color: #e96724;
    font-weight: bold;
  }
  .btn.btn-flashorange {
    border-width: 5px;
    animation-duration: 1s;
    animation-name: flashorange;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  .alert {
    background-color: rgb(247, 155, 103);
    color: white;
  }
  .ep-validation {
    color: red;
    font-size: 12px;
  }
  audio {
    display: none;
  }
  /*.video-js .vjs-control-bar {
    display:none;
  }
  */
  .video-js .vjs-live-control {
    display: none;
  }
  /* */
  .well.well-ep {
    /*background-color: #fcfcfc;*/
    background-color: white;
    border: 1px solid #e96724;
    border-radius: 0px;
    margin-bottom: 0px;
  }
  .panel-ep {
    border: solid #e96724 1px;
    border-radius: 15px;
    margin-top: -17px;
  }
  .btn-transparent {
    opacity: 0.7;
  }
  .vjs-tech {
    transform: scale(-1, 1);
  }
  .pg-header {
    text-align: center;
    padding: 25px 0px 15px;
    font-weight: bold;
    font-size: 1.3em;
  }
  .play-btn {
    font-size: 3em;
    color: rgb(233, 103, 36);
    position: absolute;
    top: 30px;
    left: 25px;
  }
  .video-card {
    background-color: #fff;
    width: calc(50% - 10px);
    margin: 5px;
    padding: 0px 0px 7px;
  }
  .video-deck {
    background-color: #f3f0f1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .blocking {
    opacity: 0;
  }
  .unblocking {
    opacity: 1;
  }
  .reveal {
    opacity: 0;
    position: relative;
  }
  .reveal.revealNow {
      animation: revealNowNotLater 2s;
      opacity: 1;
  }
  @keyframes revealNowNotLater {
      from {
        opacity: 0;
        transform: scale(0.2);
        top: 75px; /* for slide in effect */
        top: 0px; /* slide disabled */
      }
      to {
        opacity: 1;
        transform: scale(1);
        top: 0;
      }
  }


  /* rounded switch */

      /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .switchSlider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height:34px;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .switchSlider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .switchSlider {
      background-color: #e96724;
    }

    input:focus + .switchSlider {
      box-shadow: 0 0 1px #e96724;
    }

    input:checked + .switchSlider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded switchSliders */
    .switchSlider.round {
      border-radius: 34px;
    }

    .switchSlider.round:before {
      border-radius: 50%;
    }

  /* end rounded switch */


  .removedVideoNotice {
    border-radius: 10px;
    border: 1px solid grey;
    padding: 15px;
    text-align: center;
    background: white;
  }

</style>
